<template>
    <div class="order-page">
        <div class="title">
            订单业务
        </div>
        <div class="search-div">
            <!-- <el-button type="primary" size="small" @click="jumpToAddOrder()">添加快递业务</el-button> -->

                <el-select v-model="orderStatus" placeholder="订单状态" @clear="searchStatusChange" @change="searchStatusChange" size="medium" style="margin-right: 10px; width: 150px;" clearable>
                    <!-- <el-option label="已下单" value="已下单">
                    </el-option> -->
                    <el-option label="已支付" value="已支付">
                    </el-option>
                    <!-- <el-option label="已接单" value="已接单">
                    </el-option> -->
                    <el-option label="待支付" value="待支付">
                    </el-option>
                    <el-option label="派送中" value="派送中">
                    </el-option>
                    <el-option label="已送达" value="已送达">
                    </el-option>
                    <el-option label="退款中" value="退款中">
                    </el-option>
                    <el-option label="退款完成" value="退款完成">
                    </el-option>
                </el-select>
               <el-input size='medium' v-model="querySelect" placeholder="请输入订单号搜索" style="margin-right: 10px; width: 200px;"></el-input>
               <el-button type="primary" size="small" @click="searchList">搜索</el-button>

        </div>

        <div class="table-container">
            <el-table border :data="orderList">
                 <el-table-column prop="order_no" align="center" label="订单编号" width="180">
                </el-table-column>
                 <el-table-column prop="pickup_code" align="center" label="取餐码" width="100">
                </el-table-column>
                <el-table-column prop="subtitle" label="订单类型">
                    <template slot-scope="scope">
                            <div>{{orderStatus === '退款中' ? scope.row.order_detail.order_type : scope.row.order_type}}</div>
                    </template>
                </el-table-column>
                <el-table-column prop="subtitle" label="收件人信息">
                    <template slot-scope="scope">
                        <div>{{orderStatus === '退款中' ? scope.row.order_detail.recipient : scope.row.recipient}}</div>
                        <div>{{orderStatus === '退款中' ? scope.row.order_detail.phone_number : scope.row.phone_number}}</div>
                        <div>{{orderStatus === '退款中' ? scope.row.order_detail.address : scope.row.address}}</div>
                    </template>
                </el-table-column>
                <el-table-column align="center" label="订单价格（元）">
                     <template slot-scope="scope">
                        {{orderStatus === '退款中' ? (scope.row.order_detail.total_price/100).toFixed(2) : (scope.row.total_price/100).toFixed(2) }}
                    </template>
                </el-table-column>
                <el-table-column align="center" prop="delivery_code" label="取件码">
                    <template slot-scope="scope">
                        {{orderStatus === '退款中' ? scope.row.order_detail.delivery_code : scope.row.delivery_code}}
                    </template>
                </el-table-column>
                <el-table-column align="center" prop="desc" :label="orderStatus === '退款中' ? '退款原因' : '订单备注'">
                    <template slot-scope="scope">
                        {{orderStatus === '退款中' ? scope.row.order_detail.desc : scope.row.desc}}
                    </template>
                </el-table-column>
                <el-table-column align="center" prop="desc" label="商品">
                    <template slot-scope="scope">
                        <div v-if="orderStatus === '退款中'">
                            <el-button v-if="scope.row.order_good && scope.row.order_good.length >0" type="text" style="font-size: 14px;" @click="openGoodsList(scope.row.order_good)">查看商品</el-button>
                            <span v-else>-</span>
                        </div>
                        <div v-else>
                            <el-button v-if="scope.row.order_good && scope.row.order_good.length >0" type="text" style="font-size: 14px;" @click="openGoodsList(scope.row.order_good)">查看商品</el-button>
                            <span v-else>-</span>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column align="center" :label="orderStatus === '退款中' ? '退款时间' : '创建时间'">
                    <template slot-scope="scope">
                        <div>{{ scope.row.create_time | datefmt('YYYY-MM-DD HH:mm:ss') }}</div>
                        <!-- <div v-else>{{ scope.row.order_detail && scope.row.order_detail.create_time | datefmt('YYYY-MM-DD HH:mm:ss') }}</div> -->
                    </template>
                </el-table-column>
                <el-table-column align="center" label="骑手信息">
                    <template slot-scope="scope">
                        <div v-if="orderStatus === '退款中'">
                            <div v-if="scope.row.order_detail && scope.row.order_detail.rise_info">
                                <div>姓名：{{scope.row.order_detail.rise_info.name }}</div>
                                <div>电话：{{scope.row.order_detail.rise_info.phone}}</div>
                            </div>
                            <div v-else>
                                -
                            </div>
                        </div>
                        <div v-else>
                            <div v-if="scope.row.rise_info">
                                <div>姓名：{{scope.row.rise_info.name }}</div>
                                <div>电话：{{scope.row.rise_info.phone}}</div>
                            </div>
                            <div v-else>
                                -
                            </div>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="update_user_name" align="center" label="订单状态">
                    <template slot-scope="scope">
                       {{scope.row.status}}
                    </template>
                </el-table-column>
                <el-table-column align="center" width="150px" label="操作">
                    <template slot-scope="scope">
                    <!-- <div>
                        <el-popover
                            placement="top"
                            width="160"
                            :ref="`popover-${scope.row.order_id}`"
                            @hide="hidePopover">
                            <div>
                                <el-select v-model="changeSatus" placeholder="订单状态">
                                    <el-option label="已下单" value="已下单">
                                    </el-option>
                                    <el-option label="已支付" value="已支付">
                                    </el-option>
                                    <el-option label="已接单" value="已接单">
                                    </el-option>
                                    <el-option label="派送中" value="派送中">
                                    </el-option>
                                    <el-option label="已送达" value="已送达">
                                    </el-option>
                                    <el-option label="取消支付" value="取消支付">
                                    </el-option>
                                    <el-option label="退款中" value="退款中">
                                    </el-option>
                                    <el-option label="退款完成" value="退款完成">
                                    </el-option>
                                </el-select>
                                 <div style="text-align: right; margin: 10px 0 0">
                                    <el-button size="mini" @click="sureChangeSatus(scope.row)">确定</el-button>
                                </div>
                            </div>

                            <el-button slot="reference" type="text">修改状态</el-button>
                            </el-popover>
                    </div> -->
                        <el-button v-if="scope.row.status === '退款中'" @click="orderRefund(1,scope.row)" type="text">同意退款</el-button>
                        <el-button v-if="scope.row.status === '退款中'" @click="openRefundDialog(scope.row)"  type="text">拒绝退款</el-button>
                        <el-button v-if="scope.row.order_type === '外卖'" @click="printOrder(scope.row)"  type="text">打印订单</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination class="page-pageination" background layout="prev, pager, next" :total="total" :page-size="size"
            :current-page.sync="page" @current-change="getOrderList">
            </el-pagination>
        </div>

        <el-dialog title="订单商品" width="500px" :visible.sync="goodsListDialog">
            <el-table :data="goodsList" style="margin-top: -20px;">
                <el-table-column property="title" label="商品名称"></el-table-column>
                <el-table-column label="价格" align="center">
                    <template slot-scope="scope">
                          {{(scope.row.price/100).toFixed(2) }}
                    </template>
                </el-table-column>
                <el-table-column property="num" label="数量"></el-table-column>
            </el-table>
        </el-dialog>

        <el-dialog title="拒绝原因" :close-on-click-modal="false" width="400px" :visible.sync="refundDialog">
            <el-form style="margin: -30px 0">
                <el-form-item>
                    <el-input v-model="refundReason" :autosize="false" type="textarea" placeholder="请输入拒绝退款原因！" autocomplete="off"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button size="medium" @click="refundDialog = false">取 消</el-button>
                <el-button size="medium" type="primary" @click="orderRefund(2)">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>

export default {
    name: 'Order',
    data () {
        return {
            // 快递业务列表
            orderList: [],
            page: 1,
            size: 10,
            total: 0,

            goodsListDialog: false,
            goodsList: [],

            querySelect: '',
            orderStatus: '',
            visible: false,
            changeSatus: '',

            refundOrderData: {},
            refundDialog: false,
            refundReason: ''
        }
    },
    mounted () {
        this.getOrderList()
    },
    methods: {
        // 获取订单列表
        getOrderList () {
            const requestUrl = this.orderStatus === '退款中' ? '/order/get/refund/list' : '/order/list'
            this.axios.post(requestUrl, {
                page: this.page,
                size: this.size,
                order_no: this.querySelect,
                status: this.orderStatus === '' ? null : this.orderStatus
            }).then((res) => {
                this.orderList = res.data.list
                this.total = res.data.count
                console.log(res)
            })
        },

        // 修改订单状态
        sureChangeSatus (orderData) {
            if (this.changeSatus === '') {
                this.$message.error('请选择订单状态！')
                return
            }
            orderData.status = this.changeSatus
            this.axios.post('/order/update', {
                ...orderData
            }).then((res) => {
                this.$message.success('修改状态成功！')
                this.getOrderList()
            })
        },

        searchStatusChange () {
            this.page = 1
            this.getOrderList()
        },

        openRefundDialog (orderData) {
            this.refundOrderData = { ...orderData }
            this.refundDialog = true
            this.refundReason = ''
        },

        orderRefund (isAgree, orderData) {
            if (isAgree === 2 && this.refundReason === '') {
                this.$message.error('请输入拒绝退款原因！')
                return
            }
            const url = isAgree === 1 ? '/order/agree/refund' : '/order/refused/refund'
            this.axios.post(url, {
                order_no: isAgree === 1 ? orderData.order_no : this.refundOrderData.order_no,
                // out_refund_no: isAgree === 1 ? orderData.out_refund_no : this.refundOrderData.out_refund_no,
                reason_for_failure: isAgree === 2 ? this.refundReason : undefined
            }).then((res) => {
                this.$message.success(isAgree === 1 ? '退款成功' : '已拒绝退款')
                if (isAgree === 2) {
                    this.refundDialog = false
                }
                this.getOrderList()
            })
        },

        hidePopover () {
            this.changeSatus = ''
        },

        printOrder (orderData) {
            this.axios.post('/order/printer', {
                order_no: orderData.order_no
            }).then((res) => {
                this.$message.success('打印成功，请查看打印机！')
            })
        },

        openGoodsList (orderGood) {
            this.goodsList = [...orderGood]
            this.goodsListDialog = true
        },

        searchList () {
            this.page = 1
            this.getOrderList()
        }

    }
}
</script>

<style lang="scss">
    .order-page{
        .title{
            padding: 14px;
            margin-bottom: 18px;
            border-bottom: 1px solid #dddddd;
            font-size: 28px;
        }

        .search-div{
            display: flex;
            align-items: center;
            justify-content: flex-end;
            padding: 10px 20px;
            border-bottom: 1px solid #dddddd;
        }

        .table-container{
            margin: 10px 20px 0;
        }

        .page-pageination{
            padding: 10px 0;
            text-align: right;
        }
    }
</style>
